import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import AnimateHeight from 'react-animate-height'
import { colors, typography, mediaQueries as mq, animations } from 'src/styles'
import MaterialIcon from 'src/components/MaterialIcon'
import Container from 'src/components/Container'
import TextLockup from 'src/components/TextLockup'
import AnimatedArrow from 'src/components/AnimatedArrow'

const Wrapper = styled.div`
	width: 100%;
	padding-bottom: 40px;

	${ mq.smallAndBelow } {
		padding-bottom: 20px;
	}

`

const RowHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: auto;
	min-height: 46px;
	padding: 0 7px;
	&:hover {
		cursor: pointer;
	}
`

const InfoTitleWrap = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	h1 {
		margin-top: 12px;
	}
`

const RowContent = styled.div`
	border-top: 1px solid ${ colors.hrColorStrong };
`

const IconWrap = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	margin-top: 9px;
	svg {
		width: 16px;
		height: auto;
		stroke: rgba(239,239,239,0.7);
		fill: none;
	}
`

const NavigationRow = styled.div`
	border-bottom: 1px solid ${ colors.hrColorStrong };
	${ ({ firstItem }) => firstItem ? `
		border-top: 1px solid ${ colors.hrColorStrong };
	` : `` }
`

const ContactWrap = styled.div`
	// padding-bottom: 20px;
	border-top: 1px solid ${ colors.hrColorStrong };
	height: 55px;
	p {
		height: 55px;
		line-height: 55px;
		a {
			border: none;
			font-family: ${ typography.serifFont };
			position: relative;
			display: inline-block;
			line-height: 1;
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 100%;
				left: 0;
				width: 100%;
				height: 1px;
				background: ${ colors.hrColorStrong };
				transform: scaleX(0);
				transform-origin: right center;
				transition: transform ${ animations.mediumSpeed } ease-in-out;
			}
			&:hover {
				&:after {
					transform-origin: left center;
					transform: scaleX(1);
				}
			}
		}
	}
	padding: 0 7px;
`

const ContactTextLockup = styled(TextLockup)`
	p {
		height: 55px;
		line-height: 55px !important;
	}
`

const FilterWrap = styled.div`
	width: 100%;
	span {
		margin-right: 10px;
		&:hover {
			cursor: pointer;
		}
	}
	padding-top: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid ${ colors.hrColorStrong };
	.filter-tag {
		width: 15%;
		flex-grow: 0;
		flex-shrink: 0;
		${ typography.bodyMedium }
		&.active {
			text-decoration: underline;
			text-decoration-color: ${ colors.hrColorStrong };
			font-family: ${ typography.serifFont };
		}

		${ mq.smallAndBelow } {
			width: 45%;
			
		}
	}
	display: flex;
	// flex-direction: column;
	flex-wrap: wrap;
`

const ActiveFiltersWrap = styled.div`
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	flex-wrap: wrap;
`

const FilterTag = styled.div`
	background-color: ${ colors.hrColor };
	padding: 5px 15px;;
	border-radius: 100px;
	margin: 5px 10px;
	height: 30px;
	display: flex;
	align-items: center;
	.tag-name {
		font-size: 11px;
		line-height: 40px;
		margin-right: 10px;
	}
`

const Navigation = ({ 
	className,
	handleModalTrigger,
  contact,
  resetFilter,
  removeFilter,
  handleFilterSelect,
  filterTags,
  selectedFilters,
  aboutContent,
  scrolled

}) => {
	// console.log('Navigation, scrolled:', scrolled);
	const [ currentSection, toggleCurrentSection ] = useState(false)

	const toggleSection = id => {
		if (currentSection === id) {
			toggleCurrentSection(false)
		} else {
			toggleCurrentSection(id)
		}
	}

	// useEffect(() => {
	// 	console.log('useEffect')
 //    scrolled ? toggleCurrentSection(false) : false
 //  },[scrolled]);

	const filterTitleWrap = () => {
		console.log('filterTitleWrap ran', selectedFilters)
		return (
			<ActiveFiltersWrap>
			{
				selectedFilters.length > 0 ? (
					selectedFilters.map((filter, index) => {
						return (
							<FilterTag key={index}><span className="tag-name h1">{filter}</span><MaterialIcon size="13px" lineHeight="40px" onClick={(e) => {removeFilter(e, filter)}}>close</MaterialIcon></FilterTag>
						)
					})
				) : false
			}
			</ActiveFiltersWrap>
		)
	}

	return (
	  <Wrapper className={className} scrolled={scrolled}>
	  	<Container>
		  	<NavigationRow firstItem={true}>
		  		<RowHeader onClick={() => { handleModalTrigger('about', aboutContent) }}>
						<InfoTitleWrap>
							<h1>Information</h1>
						</InfoTitleWrap>
						<IconWrap>
								<svg viewBox="0 0 32 32">
									<path strokeWidth="2px" strokeLinecap="square" strokeLinejoin="miter" fill="none"  d="M30.2,0.9L16,15.1L1.8,0.9"/>
								</svg>
						</IconWrap>
					</RowHeader>
		    </NavigationRow>
		  {/*
					<path strokeWidth="2px" strokeLinecap="square" strokeLinejoin="arcs" fill="none" d="M1.000,0.827 L15.157,-13.331 L29.315,0.827 L15.157,14.985 L1.000,0.827 Z"/>

							<AnimatedArrow size={20} weight={1} direction={currentSection === 'about' ? 'up' : 'down' }/>
		  	<NavigationRow>
		  		<RowHeader onClick={() => toggleSection('contact')}>
						<InfoTitleWrap>
							<h1>Contact</h1>
						</InfoTitleWrap>
						<IconWrap>
							<AnimatedArrow size={24} weight={1} direction={currentSection === 'contact' ? 'up' : 'down' }/>
						</IconWrap>
					</RowHeader>
		      <AnimateHeight
		        duration={500}
		        delay={0}
		        animateOpacity={false}
		        height={currentSection === 'contact' ? 'auto' : 0}
		      >
		      	<ContactWrap>
			        <ContactTextLockup
								{...contact}
							/>
						</ContactWrap>
		      </AnimateHeight>
		    </NavigationRow>
	    */}
	   {/*
		    <NavigationRow>
		      <RowHeader onClick={() => toggleSection('filters')}>
						<InfoTitleWrap>
							<h1>Filter Work</h1>
						</InfoTitleWrap>
						
						{filterTitleWrap()}
						<IconWrap >
							<svg x="0px" y="0px" viewBox="0 0 31.1 15.6">
								<polygon class="st0" points="15.6,12.7 2.8,0 0,0 15.6,15.6 31.1,0 28.3,0 "/>
							</svg>
						</IconWrap>
					</RowHeader>
		      <AnimateHeight
		        duration={300}
		        delay={0}
		        animateOpacity={false}
		        height={currentSection === 'filters' ? 'auto' : 0}
		      >
		        <RowContent>
		        	<FilterWrap>
		        		<span data-slug="all" className="filter-tag" onClick={resetFilter}>Show all</span>
								{filterTags && filterTags.map((tag, index) => {
									return (
										<span key={index} data-slug={tag.node.slug} className={selectedFilters.includes(tag.node.slug) ? "filter-tag active" : "filter-tag"} onClick={() => handleFilterSelect(tag.node.slug)}>{tag.node.tag}</span>
									)
								})}
		        	</FilterWrap>
		        </RowContent>
		      </AnimateHeight>
		    </NavigationRow>
			*/}

	    </Container>
	  </Wrapper>
	)
}

export default Navigation
