import React, { Fragment } from 'react'
import styled from '@emotion/styled'

import Container from 'src/components/Container'
import Grid from 'src/components/Grid'
import TextLink from 'src/components/TextLink'
import TextLockup from 'src/components/TextLockup'
import { colors, typography, animations, mediaQueries as mq, util } from 'src/styles'



const Wrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;

  
`


const ContentWrap = styled.div`
  padding: 48px 0 48px 7px;
  ${ mq.smallAndBelow } {
    padding-top: 35px;
    padding-left: 0;
    padding-bottom: 0;
  }
`

const TitleWrapper = styled.div`
  > hr {
    width: calc(100% + 14px);
    border-color: ${ colors.hrColorStrong };
    margin-bottom: 0;
    margin: 0 -7px;
    ${ mq.smallAndBelow } {
      width: 100%;
      margin: 0;
    }
  }
  border-bottom: 1px solid ${ colors.hrColorStrong };
  padding: 36px 7px 1px;
  ${ mq.smallAndBelow } {
    padding: 36px 0 0;

  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // position: sticky;
  // top: 0;
  // z-index: 4;
  background: ${ colors.bgColor };
`

const TitleWrap = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  h2,
  h3 {
    display: inline-block;
  }
  h2 {
    line-height: 55px;
    margin: 0;
  }
  h3 {
    margin-left: 6px;
  }
`


const LeftBlock = styled.div`
  // flex-grow: 1;
  h1 {
    margin-bottom: 28px;
  }

  ${ mq.smallAndBelow } {
    padding-bottom: 50px;
    border-bottom: 1px solid ${ colors.hrColor };
  }
`

const LinksWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a {
    display: block;
    &:first-of-type {
      margin-top: 0;
    }
    margin-top: 10px;
  }

`

const RightBlock = styled.div`
  // flex-grow: 0;
  h1 {
    padding-bottom: 14px;
    border-bottom: 1px solid ${ colors.hrColorStrong };
    &:nth-of-type(2) {
      margin-top: 100px;
    }
    margin-bottom: 0;
    ${ mq.smallAndBelow } {
      &:nth-of-type(1) {
        margin-top: 0;
      }
    }
  }

  ${ mq.smallAndBelow } {
    padding: 50px 0 25px;
  }
`

const ClientsWrap = styled(TextLockup)`
  ul {
    li {
      list-style-type: none;
      border-bottom: 1px solid ${ colors.hrColor };
      padding: 10px 0;
    }
  }
`


const TeamWrap = styled(TextLockup)`
  ul {
    li {
      list-style-type: none;
      border-bottom: 1px solid ${ colors.hrColor };
      &:last-of-type {
        border-bottom: none;
      }
      // padding: 10px 0;
      p {
        ${ typography.bodyMedium }
        position: relative;
        padding-top: 5px;
        padding-bottom: 5px;
        i {
          position: absolute;
          top: 5px;
          left: 0;
          line-height: 2em;
          font-style: normal;
          visibility: visible;
          opacity: 1;
          transition: visibility ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
        }
        b{
          position: absolute;
          top: 5px;
          left: 0;
          line-height: 2em;
          visibility: hidden;
          opacity: 0;
          transition: visibility ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
          font-family: ${ typography.serifFont };
          font-weight: normal;
        }
        &:hover {
          cursor: default;
          i {
            visibility: hidden;
            opacity: 0;
          }
          b {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
`


// const ServiceItem = styled.p`
//   ${ typography.bodyMedium}
//   margin: 0;
//   border-bottom: 1px solid ${ colors.hrColor };
//   padding-top: 5px;
//   padding-bottom: 6px;
// `

const HomepageInfo = ({ className, content, title }) => {
  console.log('HomepageInfo Content:::', content)
  return (
  <Wrapper className={className}>
    <TitleWrapper>
      <hr/>
      <TitleWrap>
        {title.client && (
          <h2>{title.client}</h2>
        )}
        {title.projectTitle && (
          <h3>{title.projectTitle}</h3>
        )}
      </TitleWrap>

      
    </TitleWrapper>

    <ContentWrap>
      <Grid
        small='[1]'
        medium='[1]'
        large='[7] 1 [4]'
        vAlign='top'
        >
      <LeftBlock>
        <TextLockup
          // entranceDelay={14}
          {...content.information}
        />
        
      </LeftBlock>
      
      <RightBlock>
        <div><h1>Select Clients</h1></div>
        <ClientsWrap
          // entranceDelay={16}
          text={content.services}
          textSize="bodyMedium"
        />

        <div><h1 style={{ marginTop: '50px' }}>Previous Work</h1></div>
        <TeamWrap
          // entranceDelay={16}
          text={content.team}
          textSize="bodyMedium"
        />
       {/* {content.services.map((service, index) => {
          return (<ServiceItem key={index}>{service.tag}</ServiceItem>)
        })}*/}
      </RightBlock>

      </Grid>

  </ContentWrap>


  </Wrapper>
)}

export default HomepageInfo
