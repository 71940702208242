import React from 'react'
import styled from '@emotion/styled'

import Grid from 'src/components/Grid'
import Image from 'src/components/Image'
import Container from 'src/components/Container'
import ProjectItem from 'src/components/ProjectItem'
import { colors, animations, util, mq } from 'src/styles'

const Wrapper = styled.div`
	${ util.responsiveStyles('margin-bottom', 100, 100, 100, 0) }
	${ ({ firstItem }) => firstItem ? `
		margin-top: 0;
	` : `
		margin-top: 75px;
	` }
	margin-bottom: 75px;
	
	${ mq.smallAndBelow } {
		margin-top: 0;
		margin-bottom: 0;
	}
`

const ProjectWrapper = styled.div`
	margin: 1em 0;
`

const MediaResponsiveImage = styled(Image)`
  ${ ({ setHeight }) => setHeight !== 'auto' ? `
    height: ${ setHeight };
    > div {
      height: ${ setHeight };
      position: relative;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  ` : `` }
  border-radius: 10px;
`

const ProjectInfoWrapper = styled.div`
	padding-top: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	svg {
		display: block;
		width: 25px;
		* {
			fill: currentcolor;
		}
	}

	.h3 {
		margin-left: 6px;
	}
`

const InfoTrigger = styled.div`
	cursor: pointer;
	transition: opacity ${ animations.mediumSpeed } ease-in-out;
	&:hover {
		opacity: .6;
	}
`

const ProjectRow = ({ className, item, handleModalTrigger, filtered, filters, handlePlay, firstItem, index }) => {
	// console.log('ProjectRow: ', item)
	return (
	  <Wrapper className={className} firstItem={firstItem}>

	  	{item.rowType === 'singleFullWidth' ? (
	  			<Grid
			  		small="[1]"
			  	>	

				  	<ProjectItem
				  		delay={0}
				  		project={item.projects[0]}
				  		handleModalTrigger={handleModalTrigger}
				  		filters={filters}
				  		filtered={filtered}
				  		handlePlay={handlePlay}
				  	/>
			  	
			  	</Grid>
	  		) : item.rowType === 'single' ? (
	  			<Grid
	  				small="[1]"
			  		large="3 [6] 3"
			  	>
			  		<ProjectItem
			  			delay={0}
				  		project={item.projects[0]}
				  		handleModalTrigger={handleModalTrigger}
				  		filters={filters}
				  		filtered={filtered}
				  		handlePlay={handlePlay}
				  		debug={false}
				  	/>

			  	</Grid>
	  		) : (
	  			<Grid
			  		small="[1]"
			  		large="[1] [1]"
			  		colGap='110px'
			  	>
			  		<ProjectItem
			  			delay={0}
				  		project={item.projects[0]}
				  		handleModalTrigger={handleModalTrigger}
				  		filters={filters}
				  		filtered={filtered}
				  		handlePlay={handlePlay}
				  	/>

				  	<ProjectItem
				  		delay={14}
				  		project={item.projects[1]}
				  		handleModalTrigger={handleModalTrigger}
				  		filters={filters}
				  		filtered={filtered}
				  		handlePlay={handlePlay}
				  	/>
			  	</Grid>
	  		)}

	  	
	  </Wrapper>
	)
}

export default ProjectRow
