import React from 'react'
import styled from '@emotion/styled'
import { colors, animations, util, mq } from 'src/styles'


const ContainerStyles = styled.div`
	padding-left: 25px;
	padding-right: 25px;
	${ mq.smallAndBelow } {
    padding-left: 20px;
		padding-right: 20px;
  }
`

const Container = ({ className, ...rest }) => (
	<ContainerStyles className={className} {...rest} />
)

export default Container
