import React from 'react'
import styled from '@emotion/styled'

const timing = 'ease'
const speed = '.3s'

const Wrapper = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: top;
	overflow: visible;
	// background: red;
	${ ({ size }) => `
		width: ${ size }px;
		height: ${ size }px;
	` }
	svg {
		display: block;
		flex-grow: 0;
		flex-shrink: 0;
		overflow: visible;
		${ ({ size }) => `
			width: ${ size }px;
			height: ${ size }px;
		` }
		transition: transform ${ speed } ${ timing };
		${ ({ direction, size }) => direction === 'up' ? `
			transform: translateY(-${ size * 0.15 }px);
		` : `
			transform: translateY(${ size * 0.15 }px);
		` }
		.line-1, .line-2 {
			transition: transform ${ speed } ${ timing };
			transform-origin: 50%;
			stroke-linecap: square;
			stroke: currentcolor;
		}
		.line-1 {
			${ ({ direction, weight }) => direction === 'up' ? `
				transform: rotate(40deg);
			` : `
				transform: rotate(-40deg);
			` }
		}
		.line-2 {
			${ ({ direction, weight }) => direction === 'up' ? `
				transform: rotate(-40deg);
			` : `
				transform: rotate(40deg);
			` }
		}
	}
`

const AnimatedArrow = ({ className, weight = 1.4, size = 20, direction = 'down' }) => {
	return (
		<Wrapper className={className} direction={direction} size={size} weight={weight}>
			<svg viewBox="0 0 18.371 1.4">
				<path className='line-1' fill="none" stroke="#000000" strokeWidth={weight} strokeLinecap="square" strokeMiterlimit="10" d="M17.671,0.7H9.185"/>
				<path className='line-2' fill="none" stroke="#000000" strokeWidth={weight} strokeLinecap="square" strokeMiterlimit="10" d="M9.185,0.7L0.7,0.7"/>
			</svg>
		</Wrapper>
	)
}

export default AnimatedArrow
