import React, { Fragment, useState } from 'react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import Headroom from 'react-headroom'
import Link from 'src/components/Link'
import Logo from 'src/components/Logo'
import Grid from 'src/components/Grid'
import ScrollListener from 'src/components/ScrollListener'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import Navigation from 'src/components/Navigation'
import MobileMenu from 'src/components/MobileMenu'
import { colors, typography, animations, mq, util } from 'src/styles'

const showHide = true // show and hide header on scroll
const headerHeight = () => `height: 97px;`
const headerHeightCollapsed = util.responsiveStyles('height', 98, 98, 98, 60)


const Wrapper = styled.header`
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 4;
  pointer-events: none;
`

const HeaderContent = styled(Grid)``

const HeaderLogo = styled(Logo)`
	width: 118px;
	height: auto;
  margin: 30px 0;
	transition: color ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
	${ ({ scrolled, hasAtf, scrolledUp }) => scrolled && !scrolledUp ? `
    opacity: 0;
	` : `
		${ !hasAtf ? `
			color: ${ colors.textColor };
		` : `
			color: ${ colors.textColor };
		` }
	` }
`

const LogoCol = styled.div`
	text-align: center;
	a {
		display: inline-block;
		vertical-align: top;
    transition: none;
    max-width: 100%;
	}
`

const MenuIcon = styled.div`
	display: none;
  padding: 5px 10px;
  margin-left: -10px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  transition: color ${ animations.mediumSpeed } ease-in-out;
  span {
    display: block;
  }
`

const StyledHeadroom = styled(Headroom)`
  // height: auto !important;
  z-index: 3;
  .headroom {
    background: ${ colors.bgColor };
    top: 0;
    left: 0;
    right: 0;
    zIndex: 3;
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }
  .headroom--scrolled {
    transition: transform ${ animations.slowSpeed } ease-in-out;
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
  }
`

const Header = ({
  location,
  hasAtf = false,
  bannerText,
  collapsed,
  bannerColor,
  navigation,
  title,
  handleModalTrigger,
  contact,
  filterTitleWrap,
  resetFilter,
  removeFilter,
  handleFilterSelect,
  filterTags,
  selectedFilters,
  aboutContent
}) => {
  const [ mobileMenuOpen, toggleMobileMenu ] = useState(false)
  const [ bannerVisible ] = useState(false)

  let pageHasAtf = hasAtf && !mobileMenuOpen

  return (
    <Fragment>
  {/*
      <ScrollListener.Consumer>
        {({
          scrolledToTop,
          scrolledToBottom,
          scrollY,
          scrolledUp,
          hasScrolled,
          pageHeight
        }) => {
          let scrolled = false
          if (collapsed) {
            scrolled = true
          } else {
            scrolled = !scrolledToTop && hasScrolled
          }

          return (
            <Wrapper hasAtf={pageHasAtf} navVisible={!scrolledUp && !scrolledToTop && showHide}>
              <HeaderWrapper
                navVisible={!scrolledUp && !scrolledToTop && showHide}
                hasAtf={pageHasAtf}
                scrolled={scrolled}
                mobileMenuOpen={mobileMenuOpen}
              >
                <HeaderContent
                  small="1 [3] [6] [3] 1"
                  medium="1 [5] [2] [5] 1"
                  large="1 [8] [8] [8] 1"
                  vAlign="center"
                  hasAtf={pageHasAtf}
                  navVisible={!scrolledUp && !scrolledToTop && showHide}
                >
                  <div>
                  </div>
                  <LogoCol>
                    <Link to="/" title={title}>
                      <HeaderLogo
                        scrolled={scrolled}
                        scrolledUp={scrolledUp}
                        hasAtf={pageHasAtf}
                        mobileMenuOpen={mobileMenuOpen}
                      />
                    </Link>
                  </LogoCol>
                  <div>
                  </div>
                </HeaderContent>

                <Navigation 
                  handleModalTrigger={handleModalTrigger}
                  contact={contact}
                  resetFilter={resetFilter}
                  handleFilterSelect={handleFilterSelect}
                  filterTags={filterTags}
                  selectedFilters={selectedFilters}
                  aboutContent={aboutContent}
                  removeFilter={removeFilter}
                  scrolled={scrolled}
                />

              </HeaderWrapper>
            </Wrapper>
          )
        }}
      </ScrollListener.Consumer>
  */} 
      <StyledHeadroom
        upTolerance={50}
        disableInlineStyles={true}
      >
        <HeaderContent
          small="1 [3] [6] [3] 1"
          medium="1 [5] [2] [5] 1"
          large="1 [8] [8] [8] 1"
          vAlign="center"
          hasAtf={pageHasAtf}
          // navVisible={!scrolledUp && !scrolledToTop && showHide}
        >
          <div>
          </div>
          <LogoCol>
            <Link to="/" title={title}>
              <HeaderLogo
                // scrolled={scrolled}
                // scrolledUp={scrolledUp}
                hasAtf={pageHasAtf}
                mobileMenuOpen={mobileMenuOpen}
              />
            </Link>
          </LogoCol>
          <div>
          </div>
        </HeaderContent>

        <Navigation 
          handleModalTrigger={handleModalTrigger}
          contact={contact}
          resetFilter={resetFilter}
          handleFilterSelect={handleFilterSelect}
          filterTags={filterTags}
          selectedFilters={selectedFilters}
          aboutContent={aboutContent}
          removeFilter={removeFilter}
          // scrolled={scrolled}
        />
      </StyledHeadroom>
      <ResponsiveComponent
        small={
          <MobileMenu
            open={mobileMenuOpen}
            toggleMobileMenu={toggleMobileMenu}
            navLinks={navigation}
          // footerColumn1={footerColumn1}
          // footerColumn2={footerColumn2}
          />
        }
        medium={<span />}
      />

    </Fragment>
  )
}

export default Header
